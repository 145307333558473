import React from 'react'
import { Container, Row, Col } from '../../../components/ui/wrapper'
import Timeline from '../../../components/ui/timeline-ru'
import ImageOne from '../../../data/images/bg/lines/soft-incoming.jpg'
import ImageTwo from '../../../data/images/bg/lines/soft-storage.jpg'
import ImageThree from '../../../data/images/bg/lines/soft-planing.jpg'
import ImageFour from '../../../data/images/bg/lines/soft-production.jpg'
import ImageFive from '../../../data/images/bg/lines/soft-final.jpg'
import ImageSix from '../../../data/images/bg/lines/soft-quality.jpg'
import { SectionWrap } from './style'


const TimelineArea = (props) => {
    const content = [
        {
            year: "УЧЕТ",
            image: ImageOne,
            title: "Учет электронных компонентов",
            desc: "Автоматическая система учета электронных компонентов сводит к минимуму затраты связанные с учетом электронных компонентов. Сразу после прибытия электронные компоненты регистрируются в системе учета. Используя программное обеспечение JUKI, можно создать уникальный идентификатор или перенять существующий идентификатор из системы ERP / MES.  Уникальный идентификатор будет привязан к данным. С самого начала происходи оптимизация учета компонентов и охватывает весь цикл оборота комплектации во время производства.",
            path: "/комплексные-решения/линия-поверхностного-монтажа/линия-smt-industry-40",
            lang: "Подробно..."
        },
        {
            year: "ХРАНЕНИЕ",
            image: ImageTwo,
            title: "Автоматизированное хранение",
            desc: "Автоматизированные склады электронных компонентов могут существенно увеличь производительность производства за счет снижения к минимуму ошибок связанных с учетом комплектации. Программные решение для хранения комплектации оптимизируют оборот компонентов, производят автоматическое резервирование по проектам, обеспечивает подключение к существующим системам хранения и управления производства. На основе анализа технического задания Заказчика разрабатываются индивидуально решение для автоматизации хранения комплектации.",
            path: "/комплексные-решения/линия-поверхностного-монтажа/линия-smt-industry-40",
            lang: "Подробно..."
        },
        {
            year: "ПОДГОТОВКА",
            image: ImageThree,
            title: "Подготовка производства",
            desc: "Правильное планирование производства – это основной фактор для предотвращения простоев оборудования. Программное обеспечение JUKI одним щелчком мыши преобразует данные координат в машинные программы. Подготовка программ занимает минимальное время, а внесенные изменения можно легко перенести в другие программы. Данные для создания необходимой программы доступны в различных форматах и могут легко и быстро преобразовываются в соответствующий формат. Благодаря уникальному идентификатору, присвоенному компоненту программой, каждый компонент можно найти независимо от его местонахождения. Помимо автоматического склада, система также контролирует производственные линии, места подготовки комплектации и подготовки тележек с питателями.",
            path: "/комплексные-решения/линия-поверхностного-монтажа/линия-smt-industry-40",
            lang: "Подробно..."
        },
        {
            year: "ПРОИЗВОДСТВО",
            image: ImageFour,
            title: "Управление производством",
            desc: "В процессе производства функции программного обеспечения становятся все более важными. Процессы должны контролироваться и быть взаимосвязаны. Данные должны быть собраны для отслеживания изделий или для контроля качества и планирования. Программное обеспечение JUKI предлагает множество функций для решения этих проблем. Обратная связь между принтером паяльной пасты и системой оптической инспекции качества нанесения паяльной пасты. Настройка самого быстрого времени цикла для продукта (индивидуальная оптимизация). Несколько продуктов могут быть объединены в одну настройку (настройка кластера). Кластерные установки рекомендуются для связанных продуктов (вариантов) или отдельных  изделий в небольших количествах, чтобы минимизировать время простоя.",
            path: "/комплексные-решения/линия-поверхностного-монтажа/линия-smt-industry-40",
            lang: "Подробно..."
        },
        {
            year: "СБОРКА",
            image: ImageFive,
            title: "Управление финальной сборкой",
            desc: "С помощью программных решений можно управлять комплектацией, которая используется для финальной сборки. Данные по финальной сборке, необходимые для отслеживания комплектации, также могут быть интегрированы на сервер для централизованного управления. Данные автоматически обновляться в системе чтобы обеспечить повторный заказ в реальном времени, оптимизацию маршрутов и экономии затрат. Помимо многих других функций, также можно передавать и сохранять результаты соответствующих тестов.",
            path: "/комплексные-решения/линия-поверхностного-монтажа/линия-smt-industry-40",
            lang: "Подробно..."
        },
        {
            year: "КАЧЕСТВО",
            image: ImageSix,
            title: "Управление качеством",
            desc: "Для обеспечения прослеживаемости производственных процессов и  комплектации потребуются программные решения для  мониторинга и отчетности. Каждый шаг в производстве документируется - с момента доставки комплектации до момента отгрузки готовой продукции. Если у конечного потребителя возникнет вопрос относительно неисправного устройства, все данные могут быть полностью проверены (регистрация компонентов и  расходных материалов, параметры технологических процессов, результаты автоматической инспекции  SPI и AOI, и многое другое)",
            path: "/комплексные-решения/линия-поверхностного-монтажа/линия-smt-industry-40",
            lang: "Подробно..."
        }
    ]
    const contentua = [
        {
            year: "ОБЛІК",
            image: ImageOne,
            title: "Облік електронних компонентів",
            desc: "Автоматична система обліку електронних компонентів зводить до мінімуму витрати, пов'язані з обліком електронних компонентів. Відразу після прибуття електронні компоненти реєструються у системі обліку. Використовуючи програмне забезпечення JUKI, можна створити унікальний ідентифікатор або запозичити існуючий ідентифікатор із системи ERP/MES. Унікальний ідентифікатор буде пов'язаний з даними. З самого початку відбувайся оптимізація обліку компонентів, що охоплює весь цикл обороту комплектації під час виробництва.",
            path: "/ua/комплексні-рішення/лінія-поверхневого-монтажу/лінія-smt-industry-40",
            lang: "Докладно..."
        },
        {
            year: "ЗБЕРІГАННЯ",
            image: ImageTwo,
            title: "Автоматизоване зберігання",
            desc: "Автоматизовані склади електронних компонентів можуть суттєво збільшити продуктивність виробництва за рахунок зниження до мінімуму помилок, пов'язаних з обліком комплектації. Програмні рішення для зберігання комплектації оптимізують обіг компонентів, виробляють автоматичне резервування за проектами, забезпечують підключення до систем зберігання та управління виробництвом. На основі аналізу технічного завдання Замовника розробляються індивідуальне рішення для автоматизації зберігання комплектації і матеріалів.",
            path: "/ua/комплексні-рішення/лінія-поверхневого-монтажу/лінія-smt-industry-40",
            lang: "Докладно..."
        },
        {
            year: "ПІДГОТОВКА",
            image: ImageThree,
            title: "Підготовка виробництва",
            desc: "Правильне планування виробництва – це основний чинник запобігання простоїв обладнання. Програмне забезпечення JUKI одним кліком перетворює дані координат на машинні програми. Підготовка програм займає мінімальний час, а внесені зміни можна легко перенести до інших програм. Дані для створення необхідної програми доступні у різних форматах і можуть легко та швидко перетворюватися у потрібний формат. Завдяки унікальному ідентифікатору, наданому компоненту програмою, кожен компонент можна знайти незалежно від його місцезнаходження. Крім автоматичного складу, система також контролює виробничі лінії, місця підготовки комплектації та підготовки візків із живильниками.",
            path: "/ua/комплексні-рішення/лінія-поверхневого-монтажу/лінія-smt-industry-40",
            lang: "Докладно..."
        },
        {
            year: "ВИРОБНИЦТВО",
            image: ImageFour,
            title: "Керування виробництвом",
            desc: "У процесі виробництва функції програмного забезпечення стають все більш важливими. Процеси повинні контролюватись і бути взаємопов'язані. Дані повинні бути зібрані для відстеження виробів або контролю якості та планування. Програмне забезпечення JUKI пропонує багато функцій для вирішення цих проблем. Зворотний зв'язок між принтером паяльної пасти та системою оптичної інспекції якості нанесення паяльної пасти. Настроювання найшвидшого часу циклу для продукту (індивідуальна оптимізація). Декілька продуктів можуть бути об'єднані в одне налаштування (налаштування кластера). Кластерні установки рекомендуються для пов'язаних продуктів (варіантів) або окремих виробів у невеликих кількостях, щоб мінімізувати час простою.",
            path: "/ua/комплексні-рішення/лінія-поверхневого-монтажу/лінія-smt-industry-40",
            lang: "Докладно..."
        },
        {
            year: "СКЛАДАННЯ",
            image: ImageFive,
            title: "Управління фінальним складанням",
            desc: "За допомогою програмних рішень можна керувати комплектацією, яка використовується для фінального складання. Дані щодо фінального складання, необхідні для відстеження комплектації, також можуть бути інтегровані на сервер для централізованого керування. Дані автоматично оновлюватися в системі, щоб забезпечити повторне замовлення в реальному часі, оптимізацію маршрутів та економії витрат. Крім багатьох інших функцій, також можна передавати та зберігати результати відповідних тестів.",
            path: "/ua/комплексні-рішення/лінія-поверхневого-монтажу/лінія-smt-industry-40",
            lang: "Докладно..."
        },
        {
            year: "ЯКІСТЬ",
            image: ImageSix,
            title: "Управління якістю",
            desc: "Для забезпечення простежуваності виробничих процесів та комплектації потрібні програмні рішення для моніторингу та звітності. Кожен крок у виробництві документується з моменту доставки комплектації до моменту відвантаження готової продукції. Якщо у кінцевого споживача виникне питання щодо несправного пристрою, всі дані можуть бути повністю перевірені (реєстрація компонентів та витратних матеріалів, параметри технологічних процесів, результати автоматичної інспекції SPI та AOI, та багато іншого)",
            path: "/ua/комплексні-рішення/лінія-поверхневого-монтажу/лінія-smt-industry-40",
            lang: "Докладно..."
        }
    ]
    if (props.lang === "ru") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Для современного производства и переходу на полною автоматизацию Industry 4.0. уже не достаточно просто купить современное оборудование и обновить парк машин. Чтобы быть готовым к требованиям рынка завтрашнего дня необходимы комплексные решение для производства –  оборудование плюс интеллектуальные и гибкие программные решения. Для организации системы отвечающих вашим требованиям и потребностям необходим надежный партнер.  Программные решения, предоставляемые компанией JUKI, включает в себя отдельные модули для различных задач: учет и хранение компонентов, подготовка и управление производством, оптимизация работы производственных линий, управление финальной сборкой, управление качеством. Программное обеспечение имеет модульную структуру, что обеспечивает максимальную гибкость программных решений.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={content} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    } else if (props.lang === "uk") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Для сучасного виробництва та переходу на повну автоматизацію Industry 4.0. вже мало просто купити сучасне обладнання та оновити парк машин. Щоб бути готовим до вимог ринку завтрашнього дня, необхідні комплексні рішення для виробництва – обладнання плюс інтелектуальні та гнучкі програмні рішення. Для організації системи, що відповідають вашим вимогам та потребам, потрібен надійний партнер. Програмні рішення, що надаються компанією JUKI, включає окремі модулі для різних завдань: облік і зберігання компонентів, підготовка та управління виробництвом, оптимізація роботи виробничих ліній, управління фінальним складанням, управління якістю. Програмне забезпечення має модульну структуру, що забезпечує максимальну гнучкість програмних рішень.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={contentua} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    }
}

export default TimelineArea
